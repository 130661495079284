import React, { useState } from 'react';
import './Home.scss';



function Nosotros() {

   function scroll(id) {
      var el = window.document.getElementById(id);
      window.document.scrollingElement.scroll(0, el.offsetTop);
    }
      
  
   
   // const [seleccionado, setSeleccionado] = useState(indice);

   var resenas = [
      {
         texto: "La compra de mi terreno en Azaahy fue más sencillo de lo que creí, el trámite fue rápido, la asesoría excelente y el lugar precioso, totalmente lo que estaba buscando, para vivir tranquila.", 
         img: "/elementos/nosotros/testi.png",
         nombre: "Jessica Villegas"
      },
      {
         texto: "Acabo de adquirir mi terreno en la milpa, me encantó porque me permite estar en contacto con la naturaleza, es una tranquilidad escuchar y ver la vegetación y fauna del lugar.", 
         img: "/elementos/nosotros/testi2.png",
         nombre: "David Vicencio"
      },
      {
         texto: "Si quieren adquirir un terreno accesible, con excelente ubicación y de entrega inmediata, Amistad es una buena opción.", 
         img: "/elementos/nosotros/testi3.png",
         nombre: "Marco Espinoza"
      },
      {
         texto: "Inviertan porque es un patrimonio, es un beneficio personal y tener algo propio aquí en Cancún. No tengo queja de nada, me voy, contento, muy contento", 
         img: "/elementos/nosotros/testi4.png",
         nombre: "Daniel Naal "
      },
      {
         texto: "Los terrenos son amplios, más de lo convencional y si nos vamos a colonias como La guadalupana Aquí en Cancún, son terrenitos, casitas de 4 x 10, imagínate, entonces un terreno de 10 x 40 que es en el caso de la milpa, Recomendable.", 
         img: "/elementos/nosotros/testi5.png",
         nombre: "John Rojas"
      },
   ]

   const [indice, setIndice] = useState(0);



   const izquierda = () =>{
      if(indice == 0) return;

      setIndice(indice - 1)
   }
   const derecha = () =>{
      if(indice == resenas.length -1) return;

      setIndice(indice + 1)
   }
  
   var imgValores = [
      "/elementos/nosotros/v1.png",
      "/elementos/nosotros/v2.png",
      "/elementos/nosotros/v3.png",
      "/elementos/nosotros/v4.png",
      "/elementos/nosotros/v5.png",
      "/elementos/nosotros/v6.png",
      "/elementos/nosotros/v7.png",
      "/elementos/nosotros/v8.png",
      "/elementos/nosotros/v9.png",
      "/elementos/nosotros/v10.png",
   ]

  return (
    <React.Fragment>
       <img src="/elementos/nosotros/goUp.png" onClick={e=> scroll('inicio')} className="goUp"/>  
       <div className="us-container">
         <img className="fondo" src="/elementos/nosotros/principal.png" alt="amistad fondo nosotros"/>
         <h1 className="title">
            <span>AMISTAD BIENES RAÍCES, </span>una empresa inmobiliaria con un sólido respaldo y reconocido
            prestigio en el mercado inmobiliario de Cancún, Quintana Roo & Monclova, Coahuila.
         </h1>
         <div className="container-info">
            <div className="box-info">
               <p className="p1">
                  En Amistad Bienes Raíces nos distinguimos por la
                  constante creatividad y velocidad de respuesta en la
                  comercialización de todos los proyectos inmobiliarios
                  que ponen a tu disposición, brindándote asesoría
                  integral y transparente en el proceso de compraventa.
               </p>
               <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/AzzDM_UTA_E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
               </iframe>
            </div>
            <div className="box-info">
               <img src="elementos/nosotros/terr.png" alt="img terreno amistad" width="100" height="100"/>
               <p className="p2">
                  Entendemos la importancia de su decisión al momento de
                  comprar, vender o rentar un inmueble, es por eso que le
                  ofrecemos nuestros servicios para adquirir o arrendar una
                  casa, departamento, local o terreno, así comoasesoría
                  gratuita sobre créditos.
               </p>
            </div>
         </div>
         <div className="fondoValores">
         <div className="visMis-container">
            <div className="box-vision">
               <h1>VISIÓN</h1>
               <img src="/elementos/nosotros/barG.png" alt="barra gris amistad" />
               <p>
                  Administrar elementos humanos, materiales y
                  técnicos con mayor calidad y eficacia, para poder
                  expandirnos a otros mercados.
               </p>
            </div>
            <div className="box-mision">
               <h1>MISIÓN</h1>
               <img src="/elementos/nosotros/barB.png" alt="barra azul amistad" />
               <p>
                  Facilitar a la gente a lograr un patrimonio con
                  seguridad, integridad y confianza.
               </p>
            </div>
         </div>
         <div className="valores-container">
            <h1>Nuestros <span className="coloured-B">VALORES</span></h1>
            <div className="imgValores">
               {imgValores.map(e=> 
               <img src={e}></img>   
               )}
            </div>
         </div>
         </div>
         <div className="datosAmistad background-b">
            <div>
               <h1>+15</h1>
               <p>Desarrollos</p>
            </div>
            <div>
               <h1>+6,000</h1>
               <p>Clientes Satisfechos</p>
            </div>
            <div>
               <h1>+25</h1>
               <p>Años de experiencia</p>
            </div>
         </div>
         <div className="resenasCli-title">
            <h6>NUESTROS CLIENTES</h6>
            <p>Gracias por tu confianza & preferencia</p>
         </div>
         <div className="resena-todo">
            <i class="left fa fa-chevron-left" aria-hidden="true" onClick={() => izquierda(1)}></i>
            <i class="right fa fa-chevron-right" aria-hidden="true" onClick={() => derecha(1)} ></i>
            <div className="container-resenas">
               {resenas.map(e=>
                  <div className="resena-box"
                  //  style={{transform: 'translateX(-60em)' }}
                   style={{transform: `translateX(${indice * -60}em)`}}
                   >
                     <div className="part1">
                        <h1>“</h1>
                        <p>{e.texto}</p>
                        <div className="imgDatos">
                           <img src={e.img} alt="img testimonio" />
                           <span>{e.nombre}</span>
                        </div>
                     </div>
                     <div className="part2">
                        <img src="/elementos/nosotros/logoTesti.png" alt="" />
                        <div>
                           <h1>Bienvenido</h1>
                           <h6>A la familia AMISTAD</h6>
                        </div>
                        <p>¡Haz invertido sabiamente!</p>
                        <div className="recorte"></div>
                     </div>
                  </div>
                  )}
            </div>
         </div>
       </div>
    </React.Fragment>
  )
}

export default Nosotros;