import React, { useEffect, useState } from 'react';
import Slidewithcontrol from './slidewithcontrol'
import './Home.scss';



function FraccContent({nombre}) {

  
  
    var [index, setIndex] = useState(0);
    var [seleccionado, setSeleccionado] = useState(0);
    var [desarrollos, setDesarrollos] = useState(null);
    var [desarrollo, setDesarrollo] = useState({
       url_youtube: "",
       url_ubicacion: "",
       url_tienda: "",
       logo: "",
       texto1: "",
       texto2: "",
       info1: "",
       info2: "",
       imagenes: [""],
       amenidades: [""],
       infLista: [""]
    });
    
   // var items = desarrollo.imagenes.map(e=>{return {src: "/desarrollos/" + nombre + "/" + e}});

   var items = desarrollo.imagenes.map(e=>{return {src: e}});


    var cargarDesarrollo = async () => {
      console.log("achu", nombre);
      if(!desarrollos){
         var res = await import("./desarrollos.jsx");
         desarrollos = res.default;
         console.log(desarrollos);
         setDesarrollos(desarrollos);
      }
      console.log(desarrollos[nombre]);
      setDesarrollo(desarrollos[nombre]);
    }

    useEffect(e=> {
      cargarDesarrollo();
    }, [nombre])

  return (
    <React.Fragment>
       <div className="FraccContent">
         <Slidewithcontrol
         items={items}
         index={index}
         setIndex={setIndex}
         ></Slidewithcontrol>
         <div className="logoCentral">
            <img src={"/desarrollos/" + nombre + "/" + desarrollo.logo} alt="img logo" />
          </div>
         <div className="bakground-BLinear" style={{backgroundImage: desarrollo.color}}>
            <div className="container-elements">
            <div className="part">
               <div className="redes">
                  <div>
                     <a href={desarrollo.url_youtube} target="_blank"> <img src="/elementos/youtube.png" alt="img youtube" /></a>
                     <a href={desarrollo.url_ubicacion} target="_blank"><img src="/elementos/ubicacion.png" alt="img ubicación" /></a>
                  </div>
                  <p style={{color: desarrollo.color_txt}}>{desarrollo.texto1} </p>
               </div>
               <p className="infoFracc" style={{color: desarrollo.color_txt}}>{desarrollo.info1}</p>
            </div>
            <div className="part">
               <div className="redes">
                  <div>
                     {/* <div class="wrap">
                         <button class="button">Submit</button>
                     </div> */}
                     <a className="" href={desarrollo.url_tienda} target="_blank"><img src={"/desarrollos/" + nombre + "/" + desarrollo.imagen_tienda} alt="img tienda" /></a>
                     
                  </div>
                  <p style={{color: desarrollo.color_txt}}>{desarrollo.texto2}</p>
               </div> 
               <p className="infoFracc" style={{color: desarrollo.color_txt}}>
                  {desarrollo.info2}   
                  <ul>
                     {desarrollo.info3}
                     {desarrollo.info_lista?.map(e=> <li>{e}</li>)}
                  </ul>
               </p>
            </div>
            </div>
            <div className="listAmenidades">
               { desarrollo.amenidades.map(e=> 
                  <img src={"/desarrollos/" + nombre + "/" + e} alt="img amenidad" />
               )}
            </div>
         </div>
       </div>
    </React.Fragment>
  )
}

export default FraccContent;