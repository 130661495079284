import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { ModalBody } from "reactstrap";
import { ModalHeader } from "reactstrap";
import './NavMenu.css';

const NavMenu = () => {
  // static displayName = NavMenu.name;
  var [state, setState] = useState({
    collapsed: true
  });
  function toggleNavbar () {
    setState({
      collapsed: !state.collapsed
    });
  }

  function scroll(id) {
    var el = window.document.getElementById(id);
    window.document.scrollingElement.scroll(0, el.offsetTop);
  }

  const [seleccion, setSeleccion] = useState(null);
  var Close = () => setSeleccion(null);
  var Open = () => setSeleccion(true);

  const [seleccion2, setSeleccion2] = useState(null);
  var Close2 = () => setSeleccion2(null);
  var Open2 = () => setSeleccion2(true);

  return (
    <React.Fragment>
    <header>
      <Navbar id="inicio" className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow navbar-container" light>
        <Container>
          <NavbarBrand tag={Link} to="/"> <img src="https://amistad.z22.web.core.windows.net/fotosPagina/logosuperior.png" width="auto" height="30"></img></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse navbar-h" isOpen={!state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Inicio</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/nosotros" >Nosotros</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark"><Link to ="/#desarrollos">Nuestros Desarrollos</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-dark" href="https://ryg.amistadbr.com/" target="_blank">Recomienda & Gana</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-dark" href="https://www.portal.amistadbr.com" target="_blank">Inicia Sesión</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink tag={Link} className="text-dark show" onClick={Open}>Acceso a Clientes</NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink className="text-dark show" href="https://www.portal.amistadbr.com/" target="_blank">Acceso a Colaboradores</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink tag={Link} className="text-dark"><Link to ="/#contacto">Contacto</Link></NavLink>  
              </NavItem>
              <NavItem>
                <NavLink className="text-dark" href="https://www.blog.amistadbr.com/blog" target="_blank">Blog</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
      

      <Modal
          isOpen={seleccion != null}
          // toggle={() => setOpen(!open)}
          backdrop="static"
          centered
          size="lg"
        >
          <ModalHeader toggle={Close}>PORTAL DE CLIENTES</ModalHeader>
          <ModalBody>
            {!seleccion ? (
              ""
            ) : (
              <div className="accesoClientes">
                <div className="box">
                  <h1>CANCÚN</h1>
                  <a href="https://pagos.amistadbienesraices.com/" target="_blank">
                  <div>
                    <i className="fa fa-usd" aria-hidden="true"></i>
                    <h6>ACCESO</h6>
                  </div>
                  </a>
                  {/* <a href="https://pagos.amistadbienesraices.com/agendar-cita">
                  <div>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <h6>ATENCIÓN A CLIENTES</h6>
                  </div>
                  </a> */}
                </div>
                <div className="box">
                  <h1>MONCLOVA</h1>
                  <a href="https://pagosmva.amistadbienesraices.com/" target="_blank">
                  <div>
                   <i className="fa fa-usd" aria-hidden="true"></i>
                    <h6>ACCESO</h6>
                  </div>
                  </a>
                  {/* <a href="https://pagosmva.amistadbienesraices.com/agendar-cita|">
                  <div>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <h6>ATENCIÓN A CLIENTES</h6>
                  </div>
                  </a> */}
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={seleccion2 != null}
          // toggle={() => setOpen(!open)}
          backdrop="static"
          centered
          size="lg"
        >
          <ModalHeader toggle={Close2}>ACCESO A COLABORADORES</ModalHeader>
          <ModalBody>
            {!seleccion2 ? (
              ""
            ) : (
              <div className="accesoClientes">
                <div className="box">
                  <h1>CANCÚN</h1>
                  <a href="https://pagos.amistadbienesraices.com/bienvenida-asesores" target="_blank">
                  <div>
                    <i className="fa fa-usd" aria-hidden="true"></i>
                    <h6>APARTADO DE LOTES</h6>
                  </div>
                  </a>
                </div>
                <div className="box">
                  <h1>MONCLOVA</h1>
                  <a href="https://pagosmva.amistadbienesraices.com/bienvenida-asesores" target="_blank"> 
                  <div>
                   <i className="fa fa-usd" aria-hidden="true"></i>
                    <h6>APARTADO DE LOTES</h6>
                  </div>
                  </a>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
    </header>
    <div className="navbar-info">
        <div className="form">
          <h4>
            APARTA TU TERRENO CON TAN SOLO $1,000 M.N.{" "}
            <a href="https://wa.me/5219983860033" target="_blank">
              <span className="coloured-1">Más información {">"}</span>
            </a>
          </h4>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(NavMenu)