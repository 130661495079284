import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home_Amistad from './components/Home_Amistad';

import {ParallaxProvider } from 'react-scroll-parallax'

import './custom.css'

import Home from './components/Home.jsx';
import Nosotros from './components/Nosotros';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <React.Fragment>
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/privacidad' component={Home} />
        <Route path='/nosotros' component={Nosotros} />
      </Layout>
      </React.Fragment>
    );
  }
}
