import React, { Component, useState } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

// Carousel images 

// const demo_items = [
//     {
//         src: img4,
//         altText: 'Slide 1',
//         caption: 'Slide 1',

//     },
//     {
//         src: img5,
//         altText: 'Slide 2',
//         caption: 'Slide 2'
//     },
//     {
//         src: img6,
//         altText: 'Slide 3',
//         caption: 'Slide 3'
//     }
// ];


var animating = false;
const Slidewithcontrol = ({items, index, setIndex}) => {
    // if(!items) items = demo_items;

    var [activeIndex, _setActiveIndex] = useState(0);

    if(index != undefined){
        activeIndex = index;
    }

    const setActiveIndex = (_index) => {
        if(setIndex)
            setIndex(_index);
        else {
            _setActiveIndex(_index);
        }
    }

    function onExiting() {
        animating = true;
    }

    function onExited() {
        animating = false;
    }

    function next() {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex( nextIndex);
    }

    function previous() {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    function goToIndex(newIndex) {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    return (
        <React.Fragment>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                pause={"hover"}
                interval={false}
                previous={previous} >
                {items.map((item) => 
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src} >

                        <img src={item.src} className="d-block img-fluid" alt={item.altText} />
                    </CarouselItem>
                )}
                <CarouselControl direction="prev" directionText="Anterior" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Siguiente" onClickHandler={next} />
            </Carousel>
        </React.Fragment>
    );
    
}

export default Slidewithcontrol;   