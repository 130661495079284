import React from 'react';

const TerminosCondiciones = () => {

  var header1 = {
    marginTop:"15.75pt",
    margiRight:"0",
    marginBottom:"7.9pt",
    marginLeft:"0",
    textAlign:"center",
    lineHeight:"normal",
    fontSize:"16.0pt",
    fontFamily:'Arial, sans-serif'
  }

  var style2 = {
    marginBottom:"7.9pt",
    textAlign:"justify",
    lineHeight:"normal"
  }

  var style3 = {
    fontSize:"12.0pt",
    fontFamily:"Arial,sans-serif",
    color:"#222222"
  }
  return (<>
    <div className="WordSection1">

      <p className="MsoNormal" align="center">
        <span>Devolución o reembolso</span>
    </p>

      <p className="MsoNormal"><span >Los pagos realizados a través de esta plataforma serán aplicados al
          número de contrato ingresado por el Cliente, sirviendo como recibo el emitido
          por la aplicación y enviado al correo proporcionado por el Cliente. </span></p>

      <p className="MsoNormal" ><span >El Cliente deberá conservar sus comprobantes de pago hasta que finiquite
          el precio total del terreno, para cualquier aclaración.</span></p>

      <p className="MsoNormal" ><span >No aplicarán cambios ni devoluciones en los siguientes casos:</span></p>

      <p className="MsoListParagraphCxSpFirst" ><span ><span >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span><span >Cuando el Cliente coloque la referencia errónea en la aplicación. </span></p>

      <p className="MsoListParagraphCxSpLast" ><span ><span >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span><span>Cuando el Cliente realice un pagado erróneo dentro de su mismo lote y no
          solicite el cambio o reembolso durante los&nbsp;<b>primeros 3 (tres) días
            naturales&nbsp;</b>posteriores a la fecha del pago.</span></p>

      <p className="Prrafobsico" ><span lang="ES-TRAD" >Causales de Rescisión<b> </b></span></p>

      <p className="Prrafobsico" ><span lang="ES-TRAD" ></span></p>

      <p className="MsoNormal" ><a name="_Hlk23331992"><span >En caso de que el Cliente decidiera
        de manera unilateral Cancelar el Contrato al cual se encuentran ligados los
        pagos realizados en fecha posterior, dicha cancelación será de acuerdo a los
        términos del contrato suscrito. </span></a></p>

      <h1 className="terminoTitle" align="center" ><span ><span >Términos y
            Condiciones de Uso de Sitio Web</span></span></h1>

      <p ><span ><span >Términos y condiciones de uso de sitio web</span></span></p>

      <p ><span ><strong><span ><span >1.<span >&nbsp;&nbsp;&nbsp;
        </span></span></span></strong><strong><span >ACEPTACIÓN&nbsp;</span></strong></span></p>

      <p ><span ><span >En el presente documento (en adelante, el “convenio”) se
          establecen los términos y condiciones de Amistad Bienes y Servicios
          Empresariales S.A de C.V (Amistad Bienes Raíces), que serán de aplicación al
          acceso y uso por parte del Usuario de esta página web (el &nbsp;“Sitio Web”).</span></span></p>

      <p ><span ><span >Al acceder, consultar o utilizar el Sitio Web, los Usuarios
          aceptan cumplir los términos y condiciones establecidos en este Convenio. En
          caso de que usted no acepte quedar vinculado por los presentes términos y
          condiciones, no podrá acceder a, ni utilizar, el Sitio Web.&nbsp;Amistad Bienes
          Raíces, <span >&nbsp;</span>se reserva el derecho de
          actualizar el presente Convenio siempre que lo considere oportuno. En
          consecuencia, recomendamos al Usuario revisar periódicamente el convenio.</span></span></p>

      <p >
        <span ><span >&nbsp; </span><br />
          <br />
        </span>
      </p>

      <p ><span ><span ><span >2.<span >&nbsp;&nbsp;&nbsp;
          </span></span></span><strong><span >REQUISITOS RELATIVOS
            AL USUARIO</span></strong></span><span ><span ></span></span></p>

      <p ><span ><span >El Sitio Web y los servicios relacionados con el mismo se
          ofrecen a los Usuarios que tengan capacidad legal para otorgar contratos
          legalmente vinculantes según la legislación aplicable.&nbsp;</span></span></p>

      <p ><span ><span >Los menores no están autorizados para utilizar el Sitio Web. Si
          usted es menor de edad, por favor, no utilice esta web.&nbsp;</span></span></p>

      <p ><span ><span ><span >3.<span >&nbsp;&nbsp;&nbsp;
          </span></span></span><strong><span >LICENCIA&nbsp;</span></strong></span><span ><span ></span></span></p>

      <p ><span ><span >En este acto, Amistad Bienes Raíces, otorga al Usuario una
          licencia limitada, no exclusiva, intransferible, no susceptible de cesión y
          revocable; para consultar el contenido ofrecido en el Sitio Web, únicamente
          para uso personal del Usuario y nunca con fines comerciales.&nbsp;</span></span></p>

      <p ><span ><span >Todo el material mostrado u ofrecido en el Sitio Web, entre otros
          ejemplos, el material gráfico, los documentos, textos, imágenes, sonido, video,
          audio, las ilustraciones, el software y el código HTML (en conjunto, el
          “Contenido”), es de &nbsp;exclusiva propiedad de Amistad Bienes Raíces RH o de
          las empresas que facilitan dicho material.&nbsp;<br />
          El Contenido está protegido por las leyes de copyright mexicanas e
          internacionales, así como por las demás leyes, reglamentos y normas aplicables
          a los derechos de propiedad intelectual. Salvo disposición expresa en contrario
          en el presente contrato, y/o salvo que por imperativo legal ello esté
          expresamente permitido por leyes derogatorias de las actualmente vigentes, el
          Usuario no podrá (i) utilizar, copiar, modificar, mostrar, eliminar,
          distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender,
          revender, adaptar, invertir el proceso de creación o crear productos derivados
          a partir de, el Contenido. Tampoco podrá (ii) utilizar el Contenido en otras
          páginas Web o en cualquier medio de comunicación como, por ejemplo, en un entorno
          de red, sin la previa autorización por escrito en este sentido de Amistad
          Bienes Raíces.</span></span></p>

      <p ><span ><span >Todas las marcas comerciales, las marcas de servicio y los logos
          (en adelante, las “Marcas”) mostrados en el Sitio Web son propiedad exclusiva
          de Amistad Bienes Raíces y de sus respectivos propietarios.</span></span></p>

      <p ><span ><span >El Usuario no podrá utilizar las Marcas en modo alguno sin la
          previa autorización por escrito para ello de Amistad Bienes Raíces y los
          respectivos propietarios.</span></span></p>

      <p ><span ><strong><span ><span >4.<span >&nbsp;&nbsp;&nbsp;
        </span></span></span></strong><strong><span >INFORMACIÓN
          FACILITADA POR EL USUARIO&nbsp;</span></strong></span></p>

      <p ><span ><span >El Sitio Web ofrece al Usuario información relativa a
          promociones, ubicaciones y temas de interés relacionados con la inversión y los
          bienes raíces. En el Sitio Web el Usuario podrá consultar y realizar pagos de
          las comprar y financiamientos activos con Amistad Bienes Raíces, previa
          obtención de claves y contraseñas de acuerdo a lo dispuesto por Amistad Bienes
          Raíces de manera previa. </span></span></p>

      <p ><span ><span ></span></span></p>

      <p ><span ><strong><span ><span >5.<span >&nbsp;&nbsp;&nbsp;
        </span></span></span></strong><strong><span >PROHIBICIÓN
          GENERAL&nbsp;</span></strong></span></p>

      <p ><span ><span >Al acceder a y
        utilizar el Sitio Web, el Usuario se compromete a NO:<br />
        (a) incumplir todas las leyes, reglamentos y normas aplicables a nivel local,
        estatal, provincial, nacional (México), así como cualquier otra legislación
        aplicable.</span></span></p>

      <p ><span ><span >(c) descargar,
        enviar, transmitir o almacenar material que:</span></span></p>

      <p ><span ><span ><span >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span><span >sea ilegal, ofensivo,
            difamatorio, fraudulento, engañoso, que induzca a error, dañino, amenazador,
            hostil, obsceno o censurable;</span></span></p>

      <p ><span ><span ><span >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span><span >infrinja las
            obligaciones contractuales o de confidencialidad del Usuario;</span></span></p>

      <p ><span ><span ><span >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span><span >perjudique o
            interfiera en las aplicaciones normales del Sitio Web, como el envío o la
            transmisión de virus, gusanos o troyanos.</span></span></p>

      <p ><span ><span >(e) contravenir, o
        intentar contravenir, las medidas de seguridad del Sitio Web;</span></span></p>

      <p ><span ><span >(g) acceder o
        intentar acceder a la cuenta o al <span className="SpellE">login</span> de las
        terceras personas en el Sitio Web;</span></span></p>

      <p ><span ><span >(k) utilizar el Sitio
        Web de forma no autorizada o para alguna actividad delictiva;<br  />
        <br  />
        </span></span></p>

      <p ><span ><strong><span >6. UTILIZACIÓN DEL SITIO Y OBLIGACIONES CONCRETAS&nbsp;</span></strong></span></p>

      <p ><span ><span >Además de las obligaciones generales señaladas en el Artículo 5,
          el Usuario deberá utilizar el Sitio Web únicamente para los fines legalmente
          permitidos de consulta de información y aplicación de pagos;</span></span></p>

      <p ><span ><span ><br />
          <strong><span >7. CUENTA Y
            CONTRASEÑA&nbsp;</span></strong></span></span></p>

      <p ><span ><span >Cuando el Usuario utiliza el Sitio Web, puede optar por abrir
          una cuenta en el Sitio. En ese caso, el Usuario recibirá el número de cuenta
          virtual y la contraseña inicial que le correspondan. Es responsabilidad
          exclusiva del Usuario:<br />
          (a) mantener la confidencialidad de dichos números de cuenta virtual y
          contraseña;<br />
          (b) actualizar y comprobar frecuentemente su contraseña; y<br />
          (c) notificar inmediatamente a<span >&nbsp; </span>Amistad
          Bienes Raíces, si tiene conocimiento del uso no autorizado de su cuenta o de
          cualquier vulneración de las medidas de seguridad.</span></span></p>

      <p ><span ><strong><span >8. VÍNCULOS A PÁGINAS WEB DE TERCEROS</span></strong></span></p>

      <p ><span ><span >El Sitio Web puede tener vínculos, como hipervínculos o enlaces,
          que indican el acceso a páginas Web de terceros (los “Sitios Relacionados”).
          Aunque Amistad Bienes Raíces revisa el material de terceros y solamente permite
          la inclusión de los Sitios Relacionados que, a su leal saber y entender, no
          infringen la legislación aplicable, Amistad Bienes Raíces no puede controlar ni
          realizar un seguimiento de los Sitios Relacionados y, en consecuencia, no
          asumirá responsabilidad alguna en cuanto a la exactitud, seguridad o fiabilidad
          del material, información o contenido incluido en dichos Sitios
          Relacionados.&nbsp;</span></span></p>

      <p ><span ><span >La inclusión de los Sitios Relacionados en el Sitio Web no
          implica que haya relación o asociación alguna entre Amistad Bienes Raíces y el
          propietario de los Sitios Relacionados, ni la aprobación o promoción por parte
          de Amistad Bienes Raíces de dichos Sitios Relacionados. RH incluye los Sitios
          Relacionados solamente para comodidad del Usuario. Este es el único responsable
          del acceso a los Sitios Relacionados.&nbsp;<br />
          El Usuario deberá utilizar su propio buen criterio, precaución y sentido común
          a la hora desusar los Sitios Relacionados y, para ello, le recomendamos revisar
          el condicionado de uso y los términos que sobre privacidad rigen en dichos
          Sitios Relacionados. Amistad Bienes Raíces se reserva el derecho de eliminar de
          su Sitio Web todos los Sitios Relacionados de los que tenga conocimiento real
          que están infringiendo derechos de terceros y/o cuyo contenido vulnere la
          legislación aplicable, o si así se lo exige una orden judicial o una ordenanza
          administrativa.</span></span></p>

      <p ><span ><span >Los vínculos a nuestra página Web no están permitidos sin la
          expresa autorización, previa y por escrito, de Amistad Bienes Raíces. El
          Usuario no podrá, ni solo ni en colaboración con otras personas, ampliar,
          modificar, sustituir, enmendar o limitar, en todo o en parte, la información
          ofrecida en nuestra página Web ni las aplicaciones funcionales de la misma.
          Además, el Usuario no podrá, ni solo ni en colaboración con otras personas,
          crear o instalar vínculos desde su propia página Web o desde webs de terceros,
          a la presente página Web (ya sea mediante vínculos de hipertexto, <span className="SpellE">deep-linking</span>, enmarcado, mediante códigos o de otra forma),
          ni en todo ni en parte, sin el previo consentimiento por escrito en este
          sentido de parte de Amistad Bienes Raíces.</span></span></p>

      <p ><span ><strong><span >9. PRIVACIDAD&nbsp;</span></strong></span><span ><span ><br />
          RH procesará toda la información personal que el Usuario introduzca o facilite
          a través de nuestro Sitio Web de conformidad con la Política de<br />
          &gt; Privacidad del Sitio Web y los términos y condiciones relativos a la misma
          en este contrato.</span></span></p>

      {/* <p ><span ><strong><span >10. COOKIES</span></strong></span><span ><span ></span></span></p> */}

      <p ><span ><span ></span></span></p>

      <p ><span ><strong><span >10. DESCARGO DE RESPONSABILIDAD</span></strong></span></p>

      <p ><span ><span >EL USUARIO RECONOCE Y ACEPTA QUE:<br />
          (A) EL USUARIO ASUME TODOS LOS RIESGOS RELATIVOS A, O DERIVADOS DE, EL USO,
          CONSULTA O ACCESO POR SU PARTE AL SITIO WEB. EL SITIO WEB SE FACILITA
          "COMO ESTÁ" Y "SEGÚN DISPONIBILIDAD", SIN GARANTÍA ALGUNA;<br />
          (B) DENTRO DE LOS LÍMITES LEGALES, Amistad Bienes Raíces DECLINA EXPRESAMENTE Y
          EN ESTE ACTO, TODA GARANTÍA O RESPONSABILIDAD, EXPRESA O IMPLÍCITA, LEGAL O DE
          OTRO TIPO, INCLUIDAS, ENTRE OTRAS: LAS GARANTÍAS IMPLÍCITAS DE CALIDAD
          SATISFACTORIA, IDONEIDAD PARA EL USO CONCRETO, PROHIBICIÓN DE USO INDEBIDO Y
          LAS HABITUALES EN LA PRÁCTICA COMERCIAL O EN LAS NEGOCIACIONES EN ESTE SECTOR;
          Y<br />
          (C) AUNQUE Amistad Bienes Raíces TIENE SISTEMAS ANTI-VIRUS Y ADOPTA MEDIDAS DE
          SEGURIDAD PARA PROTEGER EL SITIO WEB Y SU CONTENIDO DE TODO TIPO DE ATAQUES
          INFORMÁTICOS CON EL FIN DE REDUCIR EL NIVEL DE RIESGO, EN ESTE ACTO Amistad
          Bienes Raíces DECLINA EXPRESAMENTE TODA GARANTÍA RESPECTO A: (I) QUE EL SITIO
          WEB Y SU CONTENIDO ESTARÁ SIEMPRE LIBRE DE ERRORES O VIRUS O NO SUFRIRÁ NUNCA
          ATAQUES DE TERCEROS; (II) EL FUNCIONAMIENTO ININTERRUMPIDO Y SIEMPRE SEGURO DEL
          SITIO WEB; (III) LA PERMANENTE DISPONIBILIDAD DEL SITIO; (IV) QUE EL SITIO WEB
          CUBRA O NO LAS NECESIDADES DEL USUARIO; Y (V) LA FIABILIDAD, EXACTITUD,
          INTEGRIDAD, VALIDEZ O VERACIDAD DE LA INFORMACIÓN FACILITADA POR EL USUARIO.</span></span></p>

      <p ><span ><strong><span >11. RESPONSABILIDAD Y LIMITACIÓN DE RESPONSABILIDAD</span></strong></span></p>

      <p ><span ><span >EL USUARIO ACEPTA ASUMIR TODOS LOS RIESGOS ASOCIADOS CON, O
          DERIVADOS DE, EL USO DEL SITIO WEB O LA INFORMACIÓN FACILITADA POR EL USUARIO. EL
          USUARIO SE COMPROMETE TAMBIÉN A EXONERAR DE TODA RESPONSABILIDAD A Amistad
          Bienes Raíces, A LA EMPRESA MATRIZ Y A LAS EMPRESAS SUBSIDIARIAS Y AFILIADAS DE
          ESTA, ASÍ COMO A LOS DIRECTIVOS, ENCARGADOS, COMERCIALES Y EMPLEADOS DE TODAS
          ELLAS, FRENTE A LAS RECLAMACIONES, DEMANDAS O RECLAMACIONES DE INDEMNIZACIÓN
          POR DAÑOS Y PERJUICIOS (DIRECTOS, INDIRECTOS Y SOBREVENIDOS) DE CUALQUIER TIPO,
          CONOCIDAS O NO, QUE PUEDAN INTERPONERSE CON BASE EN, O QUE SE DERIVEN DEL USO
          DEL SITIO WEB, LA INFORMACIÓN FACILITADA POR EL USUARIO O LAS TRANSACCIONES QUE
          SE PRODUZCAN COMO RESULTADO DEL USO DEL SITIO WEB POR PARTE DEL USUARIO.<br />
          SIEMPRE DENTRO DE LOS LÍMITES LEGALES, EL USUARIO ASUME Y ACEPTA QUE EN NINGÚN
          CASO, NI RH NI SU EMPRESA MATRIZ, LAS EMPRESAS SUBSIDIARIAS Y AFILIADAS DE
          ESTA, NI TAMPOCO SUS DIRECTIVOS, LOS COMERCIALES, LOS EMPLEADOS O LOS
          PROVEEDORES DE TODAS ELLAS, ASUMIRÁN RESPONSABILIDAD ALGUNA POR LAS PÉRDIDAS O
          LOS DAÑOS DIRECTOS, INDIRECTOS O SOBREVENIDOS INCLUIDOS, ENTRE OTROS, LOS DAÑOS
          A LA PROPIEDAD Y LOS DAÑOS PURAMENTE ECONÓMICOS.</span></span></p>

      <p className="MsoNormal" ><span ><span >Amistad bienes y Servicios
        Empresariales S.A de C.V </span></span></p>

      <p className="MsoNormal" ><span ><span >Domicilio: Av. Las Torres <span className="SpellE">Mza</span>. 43 Lote 1 Local e, SM 523. C.P. 77525. Cancún,
        Quintana Roo.</span></span><span ></span></p>

    </div>

  </>)

};

export default TerminosCondiciones